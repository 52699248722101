import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Maharashtra.css"; // Import the new CSS file
import axios from "../../utils/axiosInstance";
import defaultImage from "../../Assets/LOGO-NAME.png";
import { formatDistanceToNow } from "date-fns"; // Import date-fns for time formatting

const Maharashtra = ({ isHomepage = false }) => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNewsByCategory = async () => {
      try {
        const response = await axios.get("/news/category/Maharashtra");
        if (response.data && response.data.length > 0) {
          // Reverse the news data so the most recent one comes first
          setNewsData(response.data.reverse());
        } else {
          setError("No news available in this category.");
        }
      } catch (err) {
        console.error("Failed to fetch news:", err);
        setError("Failed to fetch news");
      } finally {
        setLoading(false);
      }
    };

    fetchNewsByCategory();
  }, []);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  // Function to truncate content based on word count
  const truncateText = (text, wordLimit = 50) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  // Function to truncate the title based on word count
  const truncateTitle = (title, wordLimit = 5) => {
    const words = title.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return title;
  };
  const displayedArticles = isHomepage ? newsData.slice(0, 5) : newsData;

  return (
    <div className="news-container-maharashtra">
      <div className="Politics-line">
        <div className="Politics-header">
          <h1>महाराष्ट्र</h1>
        </div>
      </div>

      <div className="main-news-maharashtra">
        {displayedArticles.length > 0 ? (
          <>
            <div className="featured-news-container">
              {displayedArticles.slice(0, 2).map((news) => (
                <div className="featured-news-maharashtra" key={news.news_id}>
                  <Link to={`/news/${news.news_id}`} className="featured-link">
                    <img
                      className="featured-image-maharashtra"
                      src={news.image_url || defaultImage}
                      alt={news.title}
                    />
                    <h2 className="featured-title-maharashtra">
                      {truncateTitle(news.title)} {/* Truncate title here */}
                    </h2>
                    <span className="news-date-maharashtra">
                      {formatDistanceToNow(new Date(news.created_at), {
                        addSuffix: true,
                      })}
                    </span>
                    <p className="featured-content-maharashtra">
                      {news.content && news.content.length > 300
                        ? truncateText(news.content, 50) // Truncate content here
                        : news.content}
                    </p>
                  </Link>
                </div>
              ))}
            </div>

            <div className="other-news-maharashtra">
              {displayedArticles.slice(2).map((news) => (
                <Link
                  to={`/news/${news.news_id}`}
                  key={news.news_id}
                  className="news-link-maharashtra"
                >
                  <div className="news-item-maharashtra">
                    <img
                      className="news-image-maharashtra"
                      src={news.image_url || defaultImage}
                      alt={news.title}
                    />
                    <p className="news-title-maharashtra">
                      {truncateTitle(news.title)} {/* Truncate title here */}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          </>
        ) : (
          <div className="no-news-message">No news items available.</div>
        )}
      </div>
       {/* View More Button for Homepage */}
            {isHomepage && (
              <div className="view-more-container">
                <Link to="/maharashtra" className="view-more-button">
                  View More <span className="arrow-icon">→</span>
                </Link>
              </div>
            )}
    </div>
  );
};

export default Maharashtra;
