import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { IoHomeOutline } from "react-icons/io5";
import GooeyMarquee from "../Homepage/GooeyMarquee/GooeyMarquee";
import Logo from "./LOGO-NAME.png";
import verified from "./icons8-blue-tick.svg";
// import Translate from "../Translate/Translate";
import logoName from "./logo-name-name.png";
import TopBar from "./TopBar";
const Header = () => {
  const [selected, setSelected] = useState("home");
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navRef = useRef(null);

  // Preload the logo image
  useEffect(() => {
    const img = new Image();
    img.src = Logo; // Preload the logo image
  }, []);

  useEffect(() => {
    const updateIndicator = () => {
      const selectedItem = document.querySelector(`.nav-item.${selected}`);
      if (selectedItem && navRef.current && window.innerWidth > 768) {
        const { offsetLeft, offsetWidth } = selectedItem;
        setIndicatorStyle({
          left: offsetLeft,
          width: offsetWidth,
        });
      } else {
        setIndicatorStyle({});
      }
    };

    updateIndicator();
    window.addEventListener("resize", updateIndicator);

    return () => {
      window.removeEventListener("resize", updateIndicator);
    };
  }, [selected]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="header-container">
      <div className="header">
        <TopBar />
        <div className="container-header">
          <div className="header-container">
            <div className="company-name-div">
              <svg
                className="first-svg"
                width="400"
                height="100"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M50 0 H300 Q300 75 370 75 H50 Z"
                  fill="white"
                  stroke="white"
                  stroke-width="5"
                />
              </svg>
              <svg
                className="second-svg"
                width="400"
                height="100"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="150 0 350 100"
              >
                <path
                  d="M50 0 H300 Q300 75 370 75 H50 Z"
                  fill="white"
                  stroke="white"
                  stroke-width="5"
                />
              </svg>

              <Link to="/" className="company-name-header" onClick={closeMenu}>
                <img
                  src={logoName}
                  alt="Nanded City News Logo"
                  className="company-logo"
                />
              </Link>
            </div>
            {/* Hamburger icon for mobile */}
            <div
              className="hamburger"
              onClick={toggleMenu}
              aria-expanded={isMenuOpen}
              aria-controls="nav-menu"
            >
              <i className={`fas ${isMenuOpen ? "fa-times" : "fa-bars"}`}></i>
            </div>
          </div>
          <div className="logo-container">
            <img
              src={Logo}
              alt="Nanded City News Logo"
              className="company-logo-round"
            />
          </div>
          {/* Normal navigation for larger screens */}
          <nav
            id="nav-menu"
            className={`nav-links ${isMenuOpen ? "active" : ""}`}
            ref={navRef}
            aria-label="Main Navigation"
          >
            <span className="nav-indicator" style={indicatorStyle}></span>
            <Link
              to="/"
              className={`nav-item home ${
                selected === "home" ? "selected" : ""
              }`}
              onClick={() => {
                setSelected("home");
                closeMenu();
              }}
            >
              <IoHomeOutline className="homeIcon" />
            </Link>
            <Link
              to="/nanded"
              className={`nav-item nanded ${
                selected === "nanded" ? "selected" : ""
              }`}
              onClick={() => {
                setSelected("nanded");
                closeMenu();
              }}
            >
              नांदेड जिल्हा
            </Link>
            <Link
              to="/nandedcity"
              className={`nav-item nandedcity ${
                selected === "nandedcity" ? "selected" : ""
              }`}
              onClick={() => {
                setSelected("nandedcity");
                closeMenu();
              }}
            >
              नांदेड शहर
            </Link>

            <Link
              to="/administrative"
              className={`nav-item    administrative ${
                selected === "administrative" ? "selected" : ""
              }`}
              onClick={() => {
                setSelected("administrative");
                closeMenu();
              }}
            >
              शासकीय
            </Link>
            <Link
              to="/politics"
              className={`nav-item Politics ${
                selected === "Politics" ? "selected" : ""
              }`}
              onClick={() => {
                setSelected("Politics");
                closeMenu();
              }}
            >
              राजकीय
            </Link>
            <Link
              to="/agriculture"
              className={`nav-item Agriculture ${
                selected === "Agriculture" ? "selected" : ""
              }`}
              onClick={() => {
                setSelected("Agriculture");
                closeMenu();
              }}
            >
              शेती
            </Link>
            <Link
              to="/maharashtra"
              className={`nav-item Maharashtra ${
                selected === "Maharashtra" ? "selected" : ""
              }`}
              onClick={() => {
                setSelected("Maharashtra");
                closeMenu();
              }}
            >
              राज्य
            </Link>
            <Link
              to="/country"
              className={`nav-item India ${
                selected === "India" ? "selected" : ""
              }`}
              onClick={() => {
                setSelected("India");
                closeMenu();
              }}
            >
              देश
            </Link>
          </nav>
        </div>
        <div className="home-layout">
          <div className="marquee-container">
            <div>
              <GooeyMarquee />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
