import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { Routes, Route } from "react-router-dom";
import "./App.css";
// import About from "./Pages/About";
// import Classes from "./Pages/Classes";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Administrative from "./Pages/Governence/Administrative";
import Politics from "./Pages/Politics/Politics";
import Home from "./Pages/Homepage/Home";
import Agriculture from "./Pages/Agriculture/Agriculture";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Login from "./Pages/Login/Login";
import { Navigate } from "react-router-dom";
import ProtectedRoute from "./utils/ProtectedRoute";
import Maharashtra from "./Pages/Maharashtra/Maharashtra";
import Country from "./Pages/Country/Country";
import NandedCity from "./Pages/Nanded-City/NandedCity";
import NandedRural from "./Pages/Nanded-Rural/NandedRural";
import NewsDetail from "./Pages/NewsDetail/NewsDetail";


const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Check for token on initial load
  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsAuthenticated(Boolean(token)); // Set authentication state based on token presence
  }, []);
  console.log(isAuthenticated);
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/login"
          element={
            isAuthenticated ? (
              <Navigate to="/dashboard" />
            ) : (
              <Login setIsAuthenticated={setIsAuthenticated} />
            )
          }
        />
        <Route path="/politics" element={<Politics />} />
        <Route path="/Contact" element={<ContactUs />} />
        <Route path="/administrative" element={<Administrative />} />
        <Route path="/agriculture" element={<Agriculture />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/maharashtra" element={<Maharashtra />} />
        <Route path="/country" element={<Country />} />
        <Route path="/nandedcity" element={<NandedCity />} />
        <Route path="/nanded" element={<NandedRural />} />
        <Route path="/news/:id" element={<NewsDetail />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Dashboard setIsAuthenticated={setIsAuthenticated} />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Layout>
  );
};

export default App;
