import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Country.css"; // Import the new CSS file
import axios from "../../utils/axiosInstance";
import defaultImage from "../../Assets/LOGO-NAME.png";
import InstagramPosts from "../Homepage/InstagramPosts/InstagramPosts";
import { formatDistanceToNow } from "date-fns"; // Import date-fns for time formatting
import PhoneComponent from "../Homepage/InstagramPosts/PhoneComponent";

const Marathwada = ({ isHomepage = false }) => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNewsByCategory = async () => {
      try {
        const response = await axios.get("/news/category/country");
        if (response.data && response.data.length > 0) {
          // Reverse the news data so the most recent one comes first
          setNewsData(response.data.reverse());
        } else {
          setError("No news available in this category.");
        }
      } catch (err) {
        console.error("Failed to fetch news:", err);
        setError("Failed to fetch news");
      } finally {
        setLoading(false);
      }
    };

    fetchNewsByCategory();
  }, []);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  const truncateContent = (content) => {
    const maxLength = 500;
    return content.length > maxLength
      ? content.slice(0, maxLength) + "..."
      : content;
  };

  const truncateTitle = (title, maxLength = 30) => {
    return title.length > maxLength ? title.slice(0, maxLength) + "..." : title;
  };

  // Determine the articles to display based on isHomepage prop
  const displayedArticles = isHomepage ? newsData.slice(0, 7) : newsData;

  return (
    <div className="news-container-insta">
      <div className="marathwada-heading-container">
        <div className="Politics-line">
          <div className="Politics-header">
            <h1>देश</h1>
          </div>
        </div>
        <div className="main-news-Marathwada">
          {displayedArticles.length > 0 ? (
            <>
              <div className="featured-news-marathwada">
                <Link
                  to={`/news/${displayedArticles[0].news_id}`}
                  className="featured-link-marathwada"
                >
                  <img
                    className="featured-image-marathwada"
                    src={displayedArticles[0].image_url || defaultImage}
                    alt={displayedArticles[0].title}
                  />
                  <div className="featured-content-marathwada">
                    <h2 className="featured-title-marathwada">
                      {truncateTitle(displayedArticles[0].title)}{" "}
                    </h2>
                    <span className="marathwada-news-date">
                      {formatDistanceToNow(
                        new Date(displayedArticles[0].created_at),
                        { addSuffix: true }
                      )}
                    </span>
                    <p className="featured-description-marathwada">
                      {truncateContent(displayedArticles[0].content)}
                    </p>
                  </div>
                </Link>
              </div>
              <div className="other-news-Marathwada">
                {displayedArticles.slice(1).map((news) => (
                  <Link
                    to={`/news/${news.news_id}`}
                    key={news.id}
                    className="news-item-Marathwada"
                  >
                    <img
                      className="news-image-marathwada"
                      src={news.image_url || defaultImage}
                      alt={news.title}
                    />
                    <p className="news-title">
                      {truncateTitle(news.title)}
                    </p>
                  </Link>
                ))}
              </div>
            </>
          ) : (
            <div className="no-news-message">No news items available.</div>
          )}
        </div>
          {/* View More Button for Homepage */}
      {isHomepage && (
        <div className="view-more-container">
          <Link to="/country" className="view-more-button">
            View More <span className="arrow-icon">→</span>
          </Link>
        </div>
      )}
      </div>
    
      <PhoneComponent />
    </div>
  );
};

export default Marathwada;
