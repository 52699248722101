import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./NewsComponent.css";
import axios from "../../../utils/axiosInstance";
import defaultImage from "../../../Assets/LOGO-NAME.png";
import { formatDistanceToNow } from "date-fns"; // Import date-fns for time formatting

const NewsComponent = () => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNewsByCategory = async () => {
      try {
        const response = await axios.get("/news/category/breaking");
        setNewsData(response.data);
      } catch (err) {
        console.error("Failed to fetch news:", err);
        setError("Failed to fetch news");
      } finally {
        setLoading(false);
      }
    };

    fetchNewsByCategory();
  }, []);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  const reversedNewsData = [...newsData].reverse();

  // Helper function to truncate text to a specific number of words
  const truncateTitle = (title, wordLimit = 5) => {
    const words = title.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return title;
  };

  const truncateContent = (content) => {
    const maxLength = 320;
    return content.length > maxLength
      ? content.slice(0, maxLength) + "..."
      : content;
  };

  return (
    <div className="news-layout">
      {/* Main News Section */}
      {reversedNewsData.length > 0 && (
        <div className="main-news-card">
          <img
            src={reversedNewsData[0].image_url || defaultImage}
            alt={reversedNewsData[0].title}
            className="main-news-image"
          />
          <div className="main-news-details">
            <div className="breaking-news-container">
              <span className="news-category">Nanded City News</span>
              <div className="news-meta">
                <span className="news-date">
                  {new Date().toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })}
                </span>
              </div>
            </div>
            <Link
              to={`/news/${reversedNewsData[0].news_id}`}
              className="main-news-title"
            >
              {truncateTitle(reversedNewsData[0].title)} {/* Truncated Title */}
            </Link>

            <p className="news-summary">
              {truncateContent(reversedNewsData[0].content)}
            </p>
          </div>
        </div>
      )}

      {/* Side News Section */}
      <div className="side-news-list">
        {reversedNewsData.slice(1).map((news) => (
          <Link
            to={`/news/${news.news_id}`}
            key={news.news_id}
            className="side-news-item"
          >
            <img
              src={news.image_url || defaultImage}
              alt={news.title}
              className="side-news-image"
            />
            <div className="side-news-content">
              <h4 className="side-news-title">{truncateTitle(news.title)}</h4>
              {/* Truncated Title */}
              <span className="side-news-date">
                {formatDistanceToNow(new Date(news.created_at), {
                  addSuffix: true,
                })}
              </span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default NewsComponent;
