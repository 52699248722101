import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./ScrollingNews.css";
import axios from "../../../utils/axiosInstance";

function ScrollingNews() {
  const [newsArticles, setNewsArticles] = useState([]);
  const scrollContainerRef = useRef(null);
  const scrollIntervalRef = useRef(null);

  // Fetch scrolling news from the backend
  const fetchScrollingNews = async () => {
    try {
      const response = await axios.get("/scrolling-news");
      // Reverse the order of the news articles to display the most recent first
      setNewsArticles(response.data.reverse());
      console.log("Scrolling news articles:", response.data);
    } catch (error) {
      console.error("Failed to fetch scrolling news articles:", error);
    }
  };

  // Start scrolling the content
  const startScrolling = () => {
    if (scrollIntervalRef.current) return;

    const scroll = () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollBy(0, 1);
        if (
          scrollContainerRef.current.scrollTop +
            scrollContainerRef.current.clientHeight >=
          scrollContainerRef.current.scrollHeight
        ) {
          scrollContainerRef.current.scrollTop = 0;
        }
      }
      setTimeout(() => {
        scrollIntervalRef.current = requestAnimationFrame(scroll);
      }, 30);
    };
    scrollIntervalRef.current = requestAnimationFrame(scroll);
  };

  // Stop scrolling the content
  const stopScrolling = () => {
    if (scrollIntervalRef.current) {
      cancelAnimationFrame(scrollIntervalRef.current);
      scrollIntervalRef.current = null;
    }
  };

  useEffect(() => {
    fetchScrollingNews();
    startScrolling();
    return () => stopScrolling(); // Clean up on unmount
  }, []);

  // Helper function to truncate the content
  const truncateContent = (content, maxLength) => {
    if (content.length > maxLength) {
      return content.substring(0, maxLength) + "...";
    }
    return content;
  };

  return (
    <div className="scrolling-news-container">
        <div className="scrolling-news-title backgroundImage">
      {/* <div className="backgroundImage">
          </div> */}
          <h1>ताज्या घडामोडी</h1>
          </div>
      <div
        className="scrolling-news-wrapper"
        onMouseEnter={stopScrolling}
        onMouseLeave={startScrolling}
      >
        <div ref={scrollContainerRef} className="scrolling-news-content">
          {newsArticles.length > 0 ? (
            newsArticles.map((article) => (
              <div key={article.news_id} className="scrolling-news-article">
                <Link to={`/news/${article.news_id}`} className="news-link">
                  <h2 className="scrolling-news-article-title">
                    {article.title}
                  </h2>
                </Link>
                <p className="scrolling-news-article-description">
                  {truncateContent(article.content, 100)}
                </p>
              </div>
            ))
          ) : (
            <h4>No news articles found</h4>
          )}
        </div>
      </div>
    </div>
  );
}

export default ScrollingNews;
